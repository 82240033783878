import React, { useEffect, useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DomainNameInput from "./DomainNameInput";
import DnsRecordFields from "./DnsRecordFields"; // Import the merged component
import { toast } from "react-toastify";
import { fetchData } from "../../api/FetchData";
import "./DnsSetupForm.css";

const DnsSetupForm = () => {
  const [domainName, setDomainName] = useState("");
  const [dnsRecords, setDnsRecords] = useState({
    aRecordType: "",
    aRecordName: "",
    aRecordValue: "",
    txtRecordType: "",
    txtRecordName: "",
    txtRecordValue: "",
    cnameRecordType: "",
    cnameRecordName: "",
    cnameRecordValue: "",
  });

  // Fetch the DNS records and domain name on component mount
  useEffect(() => {
    const fetchDnsData = async () => {
      try {
        const data = await fetchData("admin/get_bots"); // Adjust endpoint as necessary
        const onboardingDetails = data.onboardingDetails;

        setDomainName(onboardingDetails.domainName || "");
        setDnsRecords(onboardingDetails.dnsRecords || {});
      } catch (error) {
        console.error("Error fetching DNS data:", error);
        toast.error("Failed to load DNS data.");
      }
    };

    fetchDnsData();
  }, []);

  const generalDnsInstructions = (
    <>
      <Typography variant="body2" component="p" gutterBottom>
        Follow these steps to set up your DNS settings:
      </Typography>
      <ol>
        <li>
          <strong>Sign in to Your Domain Registrar:</strong>
          <ul>
            <li>
              Visit the website of the company where you registered your domain
              (such as GoDaddy or Namecheap).
            </li>
            <li>Enter your login credentials to access your account.</li>
          </ul>
        </li>
        <li>
          <strong>Access Domain Management:</strong>
          <ul>
            <li>
              In your account dashboard, look for a section called 'My Domains',
              'Domain Management', or similar.
            </li>
            <li>Select the domain you want to configure.</li>
          </ul>
        </li>
        <li>
          <strong>Modify DNS Records:</strong>
          <ul>
            <li>
              Find the 'DNS Settings', 'Name Server Management', or similar
              section.
            </li>
            <li>Enter the DNS records provided below.</li>
          </ul>
        </li>
        <li>
          <strong>Save Changes:</strong>
          <ul>
            <li>
              After entering all the DNS records, make sure to save your
              changes.
            </li>
          </ul>
        </li>
        <li>
          <strong>Wait for Propagation:</strong>
          <ul>
            <li>
              DNS changes can take up to 48 hours to propagate throughout the
              Internet.
            </li>
          </ul>
        </li>
        <li>
          <strong>Verification:</strong>
          <ul>
            <li>
              Once DNS settings have propagated, the 'Unverified' status on your
              dashboard will change to 'Verified'.
            </li>
            <li>
              If the status does not change after 48 hours, ensure the DNS
              records are entered correctly and if errors persist, contact us.
            </li>
          </ul>
        </li>
      </ol>
    </>
  );

  return (
    <div className="dns-setup-wrapper">
      <DomainNameInput domainName={domainName} setDomainName={setDomainName} />

      <div className="dns-container">
        <div className="title-holder">
          <h1 className="title">Dns Setup</h1>
          <div className="tooltip-container">
            <button className="help-buttons">?</button>
            <span className="tooltip-text">{generalDnsInstructions}</span>
          </div>
        </div>

        <DnsRecordFields
          title="A Record"
          instructions="A Record Setup Instructions"
          recordType={dnsRecords.aRecordType}
          recordName={dnsRecords.aRecordName}
          recordValue={dnsRecords.aRecordValue}
        />
        <DnsRecordFields
          title="TXT Record"
          instructions="TXT Record Setup Instructions"
          recordType={dnsRecords.txtRecordType}
          recordName={dnsRecords.txtRecordName}
          recordValue={dnsRecords.txtRecordValue}
        />
        <DnsRecordFields
          title="CNAME Record"
          instructions="CNAME Record Setup Instructions"
          recordType={dnsRecords.cnameRecordType}
          recordName={dnsRecords.cnameRecordName}
          recordValue={dnsRecords.cnameRecordValue}
        />
      </div>
    </div>
  );
};

export default DnsSetupForm;
