import React from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import arrowspecial from "../../img/arrowspecial.png";
import settingspecial from "../../img/settingspecial.png";

const SpecialOffer = ({ isOpen, offerEndDate }) => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Format the offerEndDate to a more readable format
  const formattedEndDate = new Date(offerEndDate).toLocaleDateString();

  // Function to handle navigation
  const handleNavigation = () => {
    navigate("/Admin/Lifetime"); // Navigate to the Lifetime Access page
  };

  return (
    <div
      className="sidebar-special-offer-wrapper"
      onClick={handleNavigation} // Add click event to navigate
      style={{ cursor: "pointer" }} // Change cursor to indicate clickability
    >
      <img src={settingspecial} alt="Settings Icon" className="settings-icon" />
      <img src={arrowspecial} alt="Arrow Icon" className="arrow-icon" />
      <div className="sidebar-special-offer">
        <div className="offer-details">
          <p className="offer-text">SPECIAL OFFER: Buy Lifetime Access!</p>
          {isOpen && (
            <>
              <p className="offer-date">Offer ends on: {formattedEndDate}</p>
              <button
                className="offer-button"
                onClick={handleNavigation} // Also add click event to the button
              >
                Lifetime Access
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecialOffer;
